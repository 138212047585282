import React from "react";

import img1 from "../../assets/image/inner-page/company-logo/dark-logo-1.png";
import imgL1 from "../../assets/image/inner-page/company-logo/light-logo-1.png";
import img3 from "../../assets/image/inner-page/company-logo/dark-logo-3.png";
import imgL3 from "../../assets/image/inner-page/company-logo/light-logo-3.png";
import img5 from "../../assets/image/inner-page/company-logo/dark-logo-5.png";
import imgL5 from "../../assets/image/inner-page/company-logo/light-logo-5.png";
import img8 from "../../assets/image/inner-page/company-logo/dark-logo-8.png";
import imgL8 from "../../assets/image/inner-page/company-logo/light-logo-8.png";
import img9 from "../../assets/image/inner-page/company-logo/dark-logo-9.png";
import imgL9 from "../../assets/image/inner-page/company-logo/light-logo-9.png";
import img10 from "../../assets/image/inner-page/company-logo/dark-logo-10.png";
import imgL10 from "../../assets/image/inner-page/company-logo/light-logo-10.png";
import img11 from "../../assets/image/inner-page/company-logo/dark-logo-11.png";
import imgL11 from "../../assets/image/inner-page/company-logo/light-logo-11.png";
import img12 from "../../assets/image/inner-page/company-logo/dark-logo-12.png";
import imgL12 from "../../assets/image/inner-page/company-logo/light-logo-12.png";
import img13 from "../../assets/image/inner-page/company-logo/dark-logo-13.png";
import imgL13 from "../../assets/image/inner-page/company-logo/light-logo-13.png";
import img14 from "../../assets/image/inner-page/company-logo/dark-logo-14.png";
import imgL14 from "../../assets/image/inner-page/company-logo/light-logo-14.png";
import img15 from "../../assets/image/inner-page/company-logo/dark-logo-15.png";
import imgL15 from "../../assets/image/inner-page/company-logo/light-logo-15.png";
import img16 from "../../assets/image/inner-page/company-logo/dark-logo-16.png";
import imgL16 from "../../assets/image/inner-page/company-logo/light-logo-16.png";
import img17 from "../../assets/image/inner-page/company-logo/dark-logo-17.png";
import imgL17 from "../../assets/image/inner-page/company-logo/light-logo-17.png";
import img18 from "../../assets/image/inner-page/company-logo/dark-logo-18.png";
import imgL18 from "../../assets/image/inner-page/company-logo/light-logo-18.png";

const SingleBrand = ({ className, imageLight, imageDark, ...rest }) => {
  return (
    <div
      className={`single-brand-logo mx-5 my-6${
        className ? " " + className : ""
      }`}
    >
      <img
        className="light-version-logo  default-logo"
        src={imageLight}
        alt=""
        {...rest}
      />
      <img className="dark-version-logo " src={imageDark} alt="" {...rest} />
    </div>
  );
};
const Company = ({ className, ...rest }) => {
  const images = [
    {
      imageDark: img1,
      imageLight: imgL1,
    },
    {
      imageDark: img3,
      imageLight: imgL3,
      delay: 200,
      duration: 200,
    },
    {
      imageDark: img5,
      imageLight: imgL5,
      delay: 400,
      duration: 200,
    },
    {
      imageDark: img8,
      imageLight: imgL8,
      delay: 600,
      duration: 200,
    },
    {
      imageDark: img9,
      imageLight: imgL9,
      delay: 800,
      duration: 200,
    },
    {
      imageDark: img10,
      imageLight: imgL10,
      delay: 1000,
      duration: 200,
    },
    {
      imageDark: img11,
      imageLight: imgL11,
      delay: 1200,
      duration: 200,
    },
    {
      imageDark: img12,
      imageLight: imgL12,
      delay: 1400,
      duration: 200,
    },
    {
      imageDark: img13,
      imageLight: imgL13,
      delay: 1600,
      duration: 200,
    },
    {
      imageDark: img14,
      imageLight: imgL14,
      delay: 1800,
      duration: 200,
    },
    {
      imageDark: img15,
      imageLight: imgL15,
      delay: 2000,
      duration: 200,
    },
    {
      imageDark: img16,
      imageLight: imgL16,
      delay: 2200,
      duration: 200,
    },
    {
      imageDark: img17,
      imageLight: imgL17,
      delay: 2400,
      duration: 200,
    },
    {
      imageDark: img18,
      imageLight: imgL18,
      delay: 2600,
      duration: 200,
    },
  ];
  return (
    <div className={className} {...rest}>
      <div className="container">
        <div className="brand-logo-small d-flex align-items-center justify-content-center flex-wrap">
          {images.map(({ imageDark, imageLight, delay, duration }, index) => {
            if (delay && duration) {
              return (
                <SingleBrand
                  imageDark={imageLight}
                  imageLight={imageDark}
                  data-aos="fade-left"
                  data-aos-once="true"
                  data-aos-delay={delay}
                  data-aos-duration={duration}
                  key={index}
                />
              );
            } else if (delay) {
              return (
                <SingleBrand
                  imageDark={imageLight}
                  imageLight={imageDark}
                  data-aos="fade-in"
                  data-aos-once="true"
                  data-aos-delay={delay}
                  key={index}
                />
              );
            } else if (duration) {
              return (
                <SingleBrand
                  imageDark={imageLight}
                  imageLight={imageDark}
                  data-aos="fade-in"
                  data-aos-once="true"
                  data-aos-duration={duration}
                  key={index}
                />
              );
            }
            return (
              <SingleBrand
                imageDark={imageLight}
                imageLight={imageDark}
                data-aos="fade-in"
                data-aos-once="true"
                key={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Company;
